<template>
  <v-container fill-height>
    <v-layout align-center justify-center>
      <v-flex xs12 sm8 md4>
        <v-card max-width="750" class="elevation-24">
          <v-card-text>
            <v-layout align-center justify-center>
              <v-img
                class="ma-12"
                src="../assets/images/logo/logo-with-app-title.svg"
                max-width="400"
              ></v-img>
            </v-layout>
            <NewPasswordRequest
              v-if="!loggingIn"
              :init-email="email"
              :new-user="true"
              v-on:success="passwordIsSet"
              :hide-cancel-btn="true"
            />
            <LoadingSpinner v-else />
          </v-card-text>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import NewPasswordRequest from "@/components/auth/NewPasswordRequest";
import user from "@/util/user";
import router from "@/router";
import LoadingSpinner from "@/components/misc/LoadingSpinner";

export default {
  name: "NewUser",
  components: { LoadingSpinner, NewPasswordRequest },

  data: () => ({
    loggingIn: false,
    email: ""
  }),

  created() {
    this.email = this.$route.query.email;
  },

  methods: {
    passwordIsSet(credentials) {
      this.loggingIn = true;
      user
        .logIn(credentials.email, credentials.password)
        .then(twoFaRequried => {
          if (twoFaRequried) {
            // Should not come here. Server should update 2FA table on
            // successfull password set
            handleFailedLogin();
          } else {
            router.push({ path: "/alert" });
          }
        })
        .catch(() => {
          handleFailedLogin();
        });
    }
  }
};

const handleFailedLogin = () => {
  window.location.href = "/";
};
</script>
